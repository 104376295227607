"use strict";

let toggleBtn = document.querySelector(".c-header__nav-btn");
let navContainer = document.querySelector(".c-header__nav-container");
let langSwitcher = document.querySelector(".c-header__lang-switcher");
let selectedLang = langSwitcher.querySelector(".c-header__selected-lang");
let langList = document.querySelector(".c-header__lang-list");

toggleBtn.addEventListener("click", function () {
  toggleBtn.classList.toggle("c-header__nav-btn_close");
  navContainer.classList.toggle("c-header__nav-container_active");
});

selectedLang.addEventListener("click", function () {
  langList.classList.toggle("c-header__lang-list_active");
});
